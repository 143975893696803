import React from 'react'
import moment from 'moment'

import ContainedButton from 'components/buttons/contained-button'
import Input from 'components/input'
import YesNoDropdown from 'components/dropdown/yesno-dropdown'

export default ({ formData, errors, onChange, onSubmit, onBack }) => {
  return (
    <form onChange={onChange} onSubmit={onSubmit}>
      <YesNoDropdown
        label="Do you agree with the above statements??"
        name="agreeToStatements"
        prompt="Select an answer"
        defaultValue={formData.agreeToStatements}
        error={errors.agreeToStatements}
        required
      />

      {formData.agreeToStatements === false && (
        <Input
          label="If no, please state reason?"
          name="noReason"
          type="text"
          error={errors.noReason}
          defaultValue={formData.noReason}
          required
        />
      )}

      <div className="mt-4">
        <div className="mb-4 space-y-4">
          <p>
            I/we understand that the information provided above will be material
            to the determination by Little Scholar, whether I/we meet the
            eligibility requirements of the Little Scholar franchise
            opportunity. I/we agree to promptly notify Little Scholar of any
            material changes to the information provided. I/we hereby certify
            that the information presented above is true and accurate, and
            understand that my/our application could be denied if any of the
            information provided is false or misleading. The submission of the
            information or acceptance of my/ our application places no
            continuing obligation on Little Scholar.
          </p>

          <p>
            I/we hereby release Little Scholar and its members, officers,
            directors and employees from any and all liabilities, claims and/or
            causes of action regarding the use or disclosure of the information
            presented above.
          </p>
        </div>

        <div className="md:flex flex-row md:space-x-4">
          <Input
            label="Applicant Signature"
            name="applicantSignature"
            type="text"
            error={errors.applicantSignature}
            defaultValue={formData.applicantSignature}
            required
          />

          <Input
            label="Date"
            name="applicantSignatureDate"
            type="date"
            max={moment().format('YYYY-MM-DD')}
            min={moment().format('YYYY-MM-DD')}
            error={errors.applicantSignatureDate}
            defaultValue={
              formData.applicantSignatureDate
                ? moment(formData.applicantSignatureDate).format('YYYY-MM-DD')
                : undefined
            }
            required
          />
        </div>

        <div className="md:flex flex-row md:space-x-4">
          <Input
            label="Co-Applicant Signature"
            name="coapplicantSignature"
            type="text"
            error={errors.coapplicantSignature}
            defaultValue={formData.coapplicantSignature}
          />

          <Input
            label="Date"
            name="coapplicantSignatureDate"
            type="date"
            max={moment().format('YYYY-MM-DD')}
            min={moment().format('YYYY-MM-DD')}
            error={errors.coapplicantSignatureDate}
            defaultValue={
              formData.coapplicantSignatureDate
                ? moment(formData.coapplicantSignatureDate).format('YYYY-MM-DD')
                : undefined
            }
            required={
              formData.coapplicantSignature !== undefined &&
              formData.coapplicantSignature !== ''
            }
          />
        </div>
      </div>

      <div className="flex flex-row justify-between">
        <ContainedButton onClick={onBack}>Back</ContainedButton>
        <ContainedButton type="submit">Submit</ContainedButton>
      </div>
    </form>
  )
}

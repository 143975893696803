import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'

import ApplicationLayout from 'components/layouts/preliminary-application-layout'
import { usePreliminaryApplication } from 'contexts/preliminary-application-context'
import FranchiseForm from 'components/pages/preliminary-application/franchise-ownership-form'

export default ({ location }) => {
  const application = usePreliminaryApplication()
  const [formData, setFormData] = useState(undefined)
  const [errors, setErrors] = useState(undefined)

  useEffect(() => {
    if (application.initialized) {
      application.setStep('franchiseOwnership')
      setFormData({ ...application.franchiseOwnership.fields })
      setErrors({ ...application.franchiseOwnership.errors })
    }
  }, [application.initialized])

  const handleFormChange = event => {
    const { name, value } = event.target

    switch (name) {
      case 'agreeToStatements':
        setFormData({
          ...formData,
          [name]: value === 'true' ? true : value === 'false' ? false : '',
        })
        break

      default:
        setFormData({ ...formData, [name]: value })
    }
  }

  const handleFormSubmit = async event => {
    event.preventDefault()
    console.log('handleFormSubmit')

    try {
      await application.update('franchiseOwnership', formData)

      // const url =
      //   process.env.FUNCTIONS_ENDPOINT +
      //   process.env.FUNCTIONS_SUBMIT_APPLICATION
      // const { success, error, data } = await Axios.post(url, {
      //   form: {
      //     generalInformation: formData,
      //   },
      // })
      //   .then(res => res.data)
      //   .catch(err => {
      //     if (err.response) return err.response.data
      //     else throw Error('An error occured')
      //   })

      // if (success) {
      //   // TODO:
      //   console.log('data', data)
      // } else {
      //   // TODO:
      //   console.log('error', error)
      // }

      navigate('/preliminary-application/submit-application')
    } catch (error) {
      // TODO:
      console.error('Error occured', error)
      window.alert('Something went wrong!')
    }
  }

  const handleGoBack = event => {
    event.preventDefault()
    navigate('/preliminary-application/general-information')
  }

  console.log('form', formData)

  if (!application.initialized || !formData) {
    return <div>Loading...</div>
  }

  return (
    <ApplicationLayout
      step="Commitment to the Franchise Ownership"
      stepNumber={4}
      location={location}
    >
      <FranchiseForm
        formData={formData}
        errors={errors}
        onChange={handleFormChange}
        onSubmit={handleFormSubmit}
        onBack={handleGoBack}
      />
    </ApplicationLayout>
  )
}
